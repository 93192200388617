const constants = {
  colorPrimary: "#303841",
  colorTernary: "#F3F3F3",
  colorBgDefault: "#212529",
  colorBgLight: "#343a40",
  colorText: "#fafafae5",
  colorBgSidebar: "#111418",
  colorHighlightPink: "#ff2cf9",
  colorHighlightPurple: "#6f42c1",
  colorHighlightGreen: "#20c997",
  colorHighlightOrange: "#fd7e14",
  fontSizeSmall: "14px",
  fontSizeParagraph: "16px",
  fontSizeHeading2: "36px",
  fontSizeHeading3: "28px",
  fontWeightParagraph: 400,
  fontWeightHeading: 600,
  fontFamilyPrimary: "Exo, sans-serif",
  fontFamilySecondary: "Teko, sans-serif",
  borderRadius: "4px",
};

const size = {
  mobile: "375px",
  tablet: "768px",
  laptop: "1024px",
  desktop: "2560px",
};

export enum Device {
  mobile = "mobile",
  tablet = "tablet",
  laptop = "laptop",
  desktop = "desktop",
}

export const device = {
  [Device.mobile]: `(min-width: ${size.mobile})`,
  [Device.tablet]: `(min-width: ${size.tablet})`,
  [Device.laptop]: `(min-width: ${size.laptop})`,
  [Device.desktop]: `(min-width: ${size.desktop})`,
};

export default constants;
