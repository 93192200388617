import React from "react";
import AboutSection from "../Components/Sections/AboutSection";
import View, { ViewContent } from "../Components/View";
import aboutViewData from "../Data/aboutViewData";

const AboutView: React.FC = () => {
  return (
    <View>
      <ViewContent>
        <AboutSection {...aboutViewData} />
      </ViewContent>
    </View>
  );
};

export default AboutView;
