import { PageData } from "../Typings/Page";

const data: PageData = {
  content:
    "I have been building web apps and software since 2010. In 2014, I programmed and installed my first interactive exhibition for a client - a collabaration with a Blender animator. My job was implementing hand detection. \n\nFor the past decade, I've been involved in conceptualizing and programming software for museums and science centres. That has been an opportunity to learn a plethora of frameworks and programming languages (python for OpenCV, C# for Unity, web tech for UI, etc). The past few years, I've been more into modern SPA solutions - React, Express, Electron, GraphQL etc. For me, Node.js with its ecosystem has been the way forward in modernizing and modularizing digital solutions.\n\nI'm wired in a way that I'm never bored - there's always so much to explore. If it's not projects for clients or employers, it's playing around with new tech or improving workspace tools. The joy of coding is making computers do the work for you.",
  content_title: "Hi. I'm <highlight>Toomas</highlight>. I program stuff.",
  title: "Who is Digilogika ?",
  infoFields: [
    {
      label: "Name",
      content: "Toomas Jaska",
    },
    {
      label: "Country",
      content: "Estonia",
      url: "https://www.visitestonia.com/en/why-estonia/estonia-is-a-digital-society",
    },
    {
      label: "Github",
      content: "[take a look]",
      url: "https://github.com/rightdroid",
    },
    {
      label: "Bitbucket",
      content: "[take a look]",
      url: "https://bitbucket.org/rightdroid/",
    },
  ],
  numbers: [
    { number: new Date().getFullYear() - 2014, text: "Years experience" },
    { number: 22, text: "Projects Done" },
    { number: 10, text: "Clients" },
  ],
};
export default data;
