import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import constants, { Device, device } from "../Shared/constants";

export enum SectionGridVariant {
  ABOUT = "ABOUT",
  SERVICES = "SERVICES",
  RESUME = "RESUME",
  SKILLS = "SKILLS",
  PORTFOLIO = "PORTFOLIO",
  TESTIMONIAL = "TESTIMONIAL",
  CONTACT = "CONTACT",
  HERO = "HERO",
}

export interface SectionProps {
  title?: string;
  titleBackdrop?: string;
  colorVariant?: "light" | "dark";
  gridVariant?: SectionGridVariant;
}

const Section: React.FC<PropsWithChildren<SectionProps>> = ({
  title,
  titleBackdrop,
  colorVariant,
  gridVariant,
  children,
}) => {
  return (
    <SectionWrap colorVariant={colorVariant}>
      <SectionInner>
        {title && (
          <SectionTitle titleBackdrop={titleBackdrop}>
            <h2>{title}</h2>
            <TitleSeparator />
          </SectionTitle>
        )}
        <SectionContent gridVariant={gridVariant}>{children}</SectionContent>
      </SectionInner>
    </SectionWrap>
  );
};

const SectionInner = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  display: block;
`;

const TitleSeparator = styled.div`
  width: 80px;
  height: 1px;
  margin: -25px auto 0;
  border-bottom: 2px solid ${constants.colorHighlightGreen};
`;

const SectionWrap = styled.div<{ colorVariant: SectionProps["colorVariant"] }>`
  background-color: ${({ colorVariant }) =>
    colorVariant === "light" ? constants.colorBgLight : constants.colorBgDefault};
  text-align: left;
  padding: 60px 30px;
  width: 100%;
`;

export const SectionContent = styled.div<{ gridVariant: SectionProps["gridVariant"] }>`
  display: grid;
  grid-template-areas: ${({ gridVariant }) => '"' + getGridVariant(gridVariant).join('"\n\r"') + '"'};

  ${({ gridVariant }) =>
    Object.values(Device)
      .map(
        deviceSize =>
          "@media " +
          device[deviceSize] +
          '{ grid-template-areas: "' +
          getGridVariant(gridVariant, deviceSize).join('"\n\r"') +
          '"}',
      )
      .join("\n\r")}

  img {
    max-width: 100%;
  }
`;

export const TitleBackdropCss = `
    position: absolute;
    transform: translateX(-50%) translateY(-45%);
    left: 50%;
    top: 50%;
    line-height: 1.3;
    color: rgb(255 255 255 / 2%);
    display: flex;
    font-weight: 800;
    letter-spacing: 5px;
    text-transform: uppercase;
    font-size: calc(50px + 1.8vw);

    @media ${device.tablet} {
      font-size: 90px;
    }
`;

const SectionTitle = styled.div<{ titleBackdrop: SectionProps["titleBackdrop"] }>`
  position: relative;
  overflow: hidden;

  h2 {
    font-size: ${constants.fontSizeHeading2};
    font-weight: ${constants.fontWeightHeading};
    text-align: center;
  }

  &:before {
    content: ${({ titleBackdrop }) => (titleBackdrop ? `"${titleBackdrop}"` : "none")};
    ${TitleBackdropCss}
  }
`;

export const SectionArea = styled.div<{ area: string }>`
  grid-area: ${({ area }) => area};
  padding: 15px;

  h3 {
    color: #fff;
    font-size: ${constants.fontSizeHeading3};
    font-weight: ${constants.fontWeightHeading};
  }

  p {
    color: #ffffff80;
    font-size: ${constants.fontSizeParagraph};
    font-weight: ${constants.fontWeightParagraph};
  }
`;

export const Highlight = styled.span`
  color: ${constants.colorHighlightGreen};
`;

function getGridVariant(variant?: SectionGridVariant, device?: Device): string[] {
  const defaultGrid = ["content"];

  if (!variant) {
    return defaultGrid;
  }

  switch (variant) {
    case SectionGridVariant.ABOUT:
      switch (device) {
        default:
          return ["sidebar", "content", "numbers"];
        case Device.mobile:
          return ["sidebar", "content", "numbers"];
        case Device.tablet:
          return ["sidebar", "content", "numbers"];
        case Device.laptop:
          return ["content sidebar", "numbers numbers"];
        case Device.desktop:
          return ["content sidebar", "numbers numbers"];
      }

    case SectionGridVariant.CONTACT:
      switch (device) {
        default:
          return ["sidebar", "content"];
        case Device.mobile:
          return ["sidebar", "content"];
        case Device.tablet:
          return ["sidebar content"];
        case Device.laptop:
          return ["sidebar content"];
        case Device.desktop:
          return ["sidebar content"];
      }

    case SectionGridVariant.HERO:
      return ["content"];

    default:
      return defaultGrid;
  }
}

export default Section;
