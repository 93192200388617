import React from "react";
import styled from "styled-components";
import constants from "../Shared/constants";

interface NumbersDisplayProps {
  number: number;
  text: string;
}

const NumbersDisplay: React.FC<NumbersDisplayProps> = ({ number, text }) => {
  return (
    <Wrap>
      <div>{number}+</div>
      <div>{text}</div>
    </Wrap>
  );
};

const Wrap = styled.div`
  display: inline-grid;
  grid-template-rows: 40px auto;
  width: auto;
  height: auto;
  align-items: center;
  padding: 20px;
  text-align: center;

  > div:nth-child(1) {
    font-size: 60px;
    font-weight: 500;
    font-family: ${constants.fontFamilySecondary};
  }

  > div:nth-child(2) {
    font-size: 12px;
  }
`;

export default NumbersDisplay;
