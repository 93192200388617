import React from "react";

const LogoIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      {...props}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || "90.88"}
      height={props.height || "104.94"}
      viewBox="0 0 90.88 104.94"
    >
      <polygon
        shapeRendering="geometricPrecision"
        points="20.45 59.31 0 34.16 0 78.71 7.26 82.9 20.45 59.31 20.45 59.31"
        fill={props.fill || "#313942"}
      />
      <polygon
        shapeRendering="geometricPrecision"
        points="60.56 39.02 46.05 63.05 37.02 78.03 51.97 96.42 54.6 99.66 69.89 90.83 81.02 73.26 66.75 49.37 60.56 39.02"
        fill={props.fill || "#313942"}
      />
      <polygon
        shapeRendering="geometricPrecision"
        points="23.52 63.09 21.16 60.2 8.18 83.43 26.23 93.85 35.73 78.11 23.52 63.09"
        fill={props.fill || "#313942"}
      />
      <polygon
        shapeRendering="geometricPrecision"
        points="36.43 78.99 27.14 94.38 45.44 104.94 53.67 100.19 51.59 97.63 36.43 78.99"
        fill={props.fill || "#313942"}
      />
      <polygon
        shapeRendering="geometricPrecision"
        points="86.88 81.02 90.88 78.71 90.88 59.67 82.26 73.28 86.88 81.02"
        fill={props.fill || "#313942"}
      />
      <polygon
        shapeRendering="geometricPrecision"
        points="45.7 14.14 39.35 3.52 0 26.24 0 32.48 21 58.32 45.7 14.14"
        fill={props.fill || "#313942"}
      />
      <polygon
        shapeRendering="geometricPrecision"
        points="71.86 89.69 85.97 81.55 81.63 74.28 71.86 89.69"
        fill={props.fill || "#313942"}
      />
      <polygon
        shapeRendering="geometricPrecision"
        points="60.57 36.97 73.2 16.03 52.53 4.09 46.92 14.12 60.57 36.97"
        fill={props.fill || "#313942"}
      />
      <polygon
        shapeRendering="geometricPrecision"
        points="24.52 62.65 36.31 77.15 45.33 62.21 59.95 37.99 46.32 15.19 21.72 59.2 24.52 62.65"
        fill={props.fill || "#313942"}
      />
      <polygon
        shapeRendering="geometricPrecision"
        points="46.29 13.08 51.61 3.56 45.44 0 40.27 2.99 46.29 13.08 46.29 13.08"
        fill={props.fill || "#313942"}
      />
      <polygon
        shapeRendering="geometricPrecision"
        points="61.18 37.99 61.18 37.99 67.13 47.95 81.65 72.26 90.88 57.69 90.88 26.24 74.12 16.56 61.18 37.99 61.18 37.99"
        fill={props.fill || "#313942"}
      />
    </svg>
  );
};

export default LogoIcon;
