import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { device } from "../../Shared/constants";
import NavigationLink from "./NavigationLink";

const Navigation: React.FC = () => {
  const [t] = useTranslation();

  return (
    <NavigationWrap>
      <NavigationLink url="/">{t("Home")}</NavigationLink>
      <NavigationLink url="/about">{t("About")}</NavigationLink>
      {/* <NavigationLink url="/blog">{t("Blog")}</NavigationLink>
      <NavigationLink url="/projects">{t("Projects")}</NavigationLink> */}
    </NavigationWrap>
  );
};

const NavigationWrap = styled.nav`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: 30px;

  @media ${device.tablet} {
    flex-direction: column;
    justify-content: flex-start;
    margin: 20px 0 0;
  }
`;

export default Navigation;
